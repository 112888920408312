import { RList, RListWrite } from '@/models/rlists'
import {
    RListListParams,
    createRList,
    deleteRList,
    listRList,
    readRList,
    updateRList,
} from './requests'
import { defineCrudStore } from '@/stores/crud'

export const useRListsBackofficeCrudStore = defineCrudStore<
    RList,
    RListWrite,
    RListListParams
>('Backoffice:RLists:Crud', {
    listRequest: listRList,
    readRequest: readRList,
    createRequest: createRList,
    updateRequest: updateRList,
    deleteRequest: deleteRList,
})
