import { RList, RListWrite } from '@/models/rlists'
import axios from 'axios'
import { ModelId } from '@/models/types'
import { API_ROOT_URL } from '@/constants'
import { operations } from '@/schema'
import { ExtractQueryParams } from '@/types'
import { serializeQueryParams } from '@/utils/requests'
import { assertTypesMatch, SameKeysGuard } from '@/utils/types'

export interface RListListParams {
    filter?: string
    page: number
    geolocation?: boolean
}

assertTypesMatch<
    SameKeysGuard<
        ExtractQueryParams<
            operations['activoice_recipients_rlists_list']['parameters']['query']
        >,
        RListListParams
    >
>()

export const createRList = async (newRList: RListWrite) => {
    return axios.post<RList>(
        `${API_ROOT_URL}/activoice/recipients/rlists/`,
        newRList
    )
}

export const deleteRList = async (rlistId: ModelId) => {
    return axios.delete(
        `${API_ROOT_URL}/activoice/recipients/rlists/${rlistId}/`
    )
}

export const readRList = async (rlistId: ModelId) => {
    return axios.get<RList>(
        `${API_ROOT_URL}/activoice/recipients/rlists/${rlistId}/`
    )
}

export const updateRList = async (rlistId: ModelId, rlist: RListWrite) => {
    return axios.put<RList>(
        `${API_ROOT_URL}/activoice/recipients/rlists/${rlistId}/`,
        rlist
    )
}

export const listRList = (params: RListListParams) =>
    axios.get(`${API_ROOT_URL}/activoice/recipients/rlists/`, {
        params: serializeQueryParams(params),
    })

export const uploadNewRlist = async (file: File, name: string) =>
    axios.post(
        `${API_ROOT_URL}/activoice/recipients/rlists/upload/`,
        {
            name: name,
            file: file,
        },
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }
    )
