<template>
    <ContainerMain class="text-center">
        <MessagePreview
            :message-text="generatedMessageText"
            :is-streaming="!store.isStreamingIdle"
            :has-copy-button="true"
        />
        <TransitionExpands>
            <CenteredContainer
                v-show="store.isStreamingIdle"
                class="overflow-hidden"
            >
                <ButtonGenerateOther
                    class="mb-5"
                    label-t-key="user.campaign.comment_generate_other"
                    @generate-message="store.reformulateContentSample()"
                />
                <AVButton
                    v-if="generatedMessageText && store.isStreamingIdle"
                    :sticky-on-mobile="true"
                    @click="displayCopiedContentOverlay(generatedMessageText)"
                    >{{ buttonText }}
                </AVButton>

                <GenerateCommentOverlay
                    :campaign-id="props.campaignId"
                    v-model="isShowingSendMsgOverlay"
                    @send-button-clicked="emit('sendButtonClicked')"
                />
            </CenteredContainer>
        </TransitionExpands>
    </ContainerMain>
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useSendCampaignStore } from '@/AppCampaigns/stores'
import { computed, ref } from 'vue'
import { ContentSampleComment } from '@/models/content-samples'
import AVButton from '@/components/forms/AVButton.vue'
import CenteredContainer from '../../CenteredContainer.vue'
import ContainerMain from '../ContainerMain.vue'
import TransitionExpands from '../TransitionExpands.vue'
import { writeTextToClipboard } from '../utils'
import MessagePreview from './MessagePreview.vue'
import ButtonGenerateOther from './ButtonGenerateOther.vue'

import { SendButtonsEmits } from '../../types'

import GenerateCommentOverlay from './GenerateCommentOverlay.vue'

interface Emits extends SendButtonsEmits {}

const emit = defineEmits<Emits>()

interface Props {
    campaignId: string | null
}
const props = defineProps<Props>()

const { t } = useI18n()
const store = useSendCampaignStore(props.campaignId)

const generatedMessageText = computed(() =>
    store.generatedContent
        ? (store.generatedContent as ContentSampleComment).text
        : null
)

const isShowingSendMsgOverlay = ref(false)

const displayCopiedContentOverlay = (content: string) => {
    writeTextToClipboard(content)
    isShowingSendMsgOverlay.value = true
}

const buttonText = computed(() => {
    switch (store.interpellationMode!) {
        case 'facebook':
        case 'instagram':
            return t('user.campaign.comment_copy')
        case 'form':
            return store.interpellation!.custom_button_text
                ? store.interpellation!.custom_button_text
                : t('user.campaign.send_button_form')
        default:
            throw new Error(
                'Interpellation mode not supported ' + store.interpellationMode
            )
    }
})
</script>
