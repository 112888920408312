<template>
    <AVOverlayWhite v-if="modelValue" @close="closeOverlay">
        <div class="text-lg max-sm:text-left mb-2">
            {{ t('user.campaign.comment_copied_to_clipboard') }}
        </div>
        <div
            v-if="isForm && store.interpellation!.custom_text_before_button"
            class="mt-2 mb-2 md:max-w-[60%] mx-auto text-left"
            v-html="store.interpellation!.custom_text_before_button"
        ></div>
        <MessageInstructions
            v-else
            :instructions-t-key="getInstructionTKey()"
        />
        <SendButtons
            :send-links="finalLinksList"
            @send-button-clicked="
                () => {
                    emit('sendButtonClicked')
                    closeOverlay()
                }
            "
            :campaign-id="props.campaignId"
        />
        <AVButton
            v-if="isSocialMedia"
            v-show="sendLinks.length > INITIAL_RECIPIENTS_SHOWN"
            @click="onClickedShowMoreRecipients"
            button-type="link"
            class="mt-6 mb-8"
        >
            {{ t('user.campaign.recipient_load_more') }}
        </AVButton>
    </AVOverlayWhite>
</template>

<script setup lang="ts">
import { useSendCampaignStore } from '@/AppCampaigns/stores'
import AVOverlayWhite from '@/components/AVOverlayWhite.vue'
import { useI18n } from 'vue-i18n'
import { computed, ref } from 'vue'
import shuffle from 'lodash/shuffle'
import MessageInstructions from './MessageInstructions.vue'
import SendButtons from './SendButtons.vue'
import { recipientDisplay, RecipientPublic } from '@/models/recipients'
import { SendButtonsEmits } from '../../types'

interface Props {
    campaignId: string | null
    modelValue: boolean
}
const props = defineProps<Props>()

interface Emits extends SendButtonsEmits {
    (event: 'update:modelValue', value: boolean): void
}

const emit = defineEmits<Emits>()

const store = useSendCampaignStore(props.campaignId)
const { t } = useI18n()

const closeOverlay = () => {
    emit('update:modelValue', false)
}

const INITIAL_RECIPIENTS_SHOWN = 3
const totalRecipientsShown = ref(INITIAL_RECIPIENTS_SHOWN)
const onClickedShowMoreRecipients = () => {
    totalRecipientsShown.value =
        totalRecipientsShown.value + INITIAL_RECIPIENTS_SHOWN
}

const isForm = computed(() => store.interpellationMode === 'form')

const sendLinks = computed(() =>
    shuffle(store.recipients).map((recipient) => ({
        href: getCommentLink(recipient),
        label: recipientDisplay(recipient),
    }))
)

const selectedSendLinks = computed(() =>
    sendLinks.value.slice(0, totalRecipientsShown.value)
)

const getInstructionTKey = () => {
    if (selectedSendLinks.value.length === 1) {
        return 'user.campaign.comment_instructions_single'
    } else {
        return 'user.campaign.comment_instructions'
    }
}

const getCommentLink = (recipient: RecipientPublic) => {
    switch (store.interpellationMode!) {
        case 'facebook':
            return recipient.facebook_page!
        case 'instagram':
            return `https://instagram.com/${recipient.instagram_handle}`
        default:
            return ''
    }
}

const finalLinksList = computed(() => {
    switch (store.interpellationMode!) {
        case 'facebook':
        case 'instagram':
            return selectedSendLinks.value
        case 'form':
            return [
                {
                    href: store.interpellation!.destination_url!,
                    label: store.interpellation!.custom_button_text
                        ? store.interpellation!.custom_button_text
                        : t('user.campaign.send_button_form'),
                },
            ]
        default:
            throw new Error(
                'Interpellation mode not supported ' + store.interpellationMode
            )
    }
})

const isSocialMedia = computed(() =>
    ['facebook', 'instagram'].includes(store.interpellationMode!)
)
</script>
