<template>
    <CenteredContainer class="md:w-full mt-4 space-y-5">
        <AVButton
            v-for="{ href, label } in sendLinks"
            :key="href"
            class="md:max-w-[20em] w-full"
            button-type="secondary"
            :sticky-on-mobile="sendLinks.length === 1"
            type="link"
            :href="href"
            @click="() => emit('sendButtonClicked')"
        >
            <span v-if="sendLinks.length === 1">
                {{ getButtonLabelForSingleSendLink(label) }}
            </span>
            <span v-else>
                {{ label }}
            </span>
            <i
                class="fi fi-rs-angle-right relative bold text-[80%] top-[0.18em] ml-3"
            />
        </AVButton>
        <div class="text-[12px] max-sm:pb-10">
            {{ t('user.email.courtesy_warning') }}
        </div>
    </CenteredContainer>
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useSendCampaignStore } from '@/AppCampaigns/stores'
import CenteredContainer from '../../CenteredContainer.vue'
import AVButton from '@/components/forms/AVButton.vue'
import { SendButtonsEmits } from '../../types'

const { t } = useI18n()

interface Props {
    sendLinks: Array<{ href: string; label: string }>
    campaignId: string | null
}

interface Emits extends SendButtonsEmits {}

const emit = defineEmits<Emits>()

const props = defineProps<Props>()

const store = useSendCampaignStore(props.campaignId)

const getButtonLabelForSingleSendLink = (label: string) => {
    switch (store.interpellationMode) {
        case 'facebook':
            return t('user.campaign.go_interpellation_mode.facebook')
        case 'instagram':
            return t('user.campaign.go_interpellation_mode.instagram')
        default:
            return label
    }
}
</script>
