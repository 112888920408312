import { CampaignPublic, InterpellationMode } from './campaigns'
import { ContentSample } from './content-samples'
import { InterpellationPublic } from './interpellations'
import { areSameRecipients, RecipientPublic } from './recipients'

export const selectInterpellationRecipients = (
    interpellation: InterpellationPublic
): Array<RecipientPublic> => {
    let allRecipients = interpellation.send_to

    // Add in all recipients from all recipients lists
    ;(interpellation!.recipient_lists || []).forEach((rList) => {
        if (rList.recipients) {
            allRecipients = allRecipients.concat(rList.recipients)
        }
    })

    const dedupedRecipients: Array<RecipientPublic> = []
    allRecipients.forEach((recipient) => {
        if (
            dedupedRecipients.every(
                (other) => !areSameRecipients(recipient, other)
            )
        ) {
            dedupedRecipients.push(recipient)
        }
    })
    return dedupedRecipients
}

export const selectInterpellationModeRecipients = (
    recipients: Array<RecipientPublic>,
    mode: InterpellationMode
): Array<RecipientPublic> => {
    switch (mode) {
        case 'email':
            return recipients.filter((recipient) => recipient.email)
        case 'facebook':
            return recipients.filter((recipient) => recipient.facebook_page)
        case 'twitter':
            return recipients.filter((recipient) => recipient.twitter_handle)
        case 'instagram':
            return recipients.filter((recipient) => recipient.instagram_handle)
        case 'form':
            return recipients
    }
}

export const selectContentSamplesForInterpellationMode = (
    interpellation: InterpellationPublic,
    interpellationMode: InterpellationMode
) => {
    const contentSampleType =
        selectContentSampleTypeForInterpellationMode(interpellationMode)
    return interpellation.content_samples.filter(
        (contentSample) => contentSample.type === contentSampleType
    )
}

export const selectInterpellationModesAvailable = (
    campaign: CampaignPublic,
    interpellation: InterpellationPublic,
    recipients: Array<RecipientPublic>
) =>
    interpellation
        ? campaign.interpellation_modes.filter(
              (mode) =>
                  // Check if there are recipients for the interpellation mode
                  selectInterpellationModeRecipients(recipients, mode).length >
                      0 &&
                  // Check if there are content samples for the interpellation mode
                  interpellation.content_samples.filter(
                      (contentSample) =>
                          contentSample.type ===
                          selectContentSampleTypeForInterpellationMode(mode)
                  ).length > 0
          )
        : campaign.interpellation_modes

export const selectContentSampleTypeForInterpellationMode = (
    interpellationMode: InterpellationMode
): ContentSample['type'] =>
    (
        ({
            email: 'email',
            facebook: 'comment',
            instagram: 'comment',
            twitter: 'tweet',
            form: 'email',
        }) as const
    )[interpellationMode]
